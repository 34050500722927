import React from "react";
import footer1 from "../assets/images/footer-1-1.png";
import footer2 from "../assets/images/footer-1-2.png";
import footer3 from "../assets/images/footer-1-3.png";
import footer4 from "../assets/images/footer-1-4.png";
import footer5 from "../assets/images/footer-1-5.png";
import footer6 from "../assets/images/footer-1-6.png";
import { Link } from "gatsby"

const Footer = () => {
  function scrollTop() {
    window.scrollTo(0, 0);
  }
  return (
    <div>
      <footer className="site-footer">
        <div className="site-footer__upper">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-sm-12">
                <div className="footer-widget footer-widget__contact">
                  <h2 className="footer-widget__title">Proyectos</h2>
                  <ul className="list-unstyled footer-widget__course-list">
                    <li>
                      <h2>
                        <Link to='/proyectos/bloodbowlcodex'>
                          Una aplicación perfecta para los fanáticos de los fanáticos de Blood Bowl, tu compendio de normas para que no te deslomes con los manuales
                        </Link>
                      </h2>
                      <p>Blood Bowl Codex</p>
                    </li>
                    <li>
                      <h2>
                        <Link to="/proyectos/rolteca">
                          {" "}
                          Repositorio de recursos y juegos de rol{" "}
                        </Link>
                      </h2>
                      <p>Rolteca</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-12">
                <div className="footer-widget footer-widget__link">
                  <h2 className="footer-widget__title">Explorador</h2>
                  <div className="footer-widget__link-wrap">
                    <ul className="list-unstyled footer-widget__link-list">
                      <li>
                        <a href="/">Inicio</a>
                      </li>
                      <li>
                        <a href="/juegos/">Juegos</a>
                      </li>
                      <li>
                        <a href="/proyectos/">Proyectos</a>
                      </li>
                      <li>
                        <a href="/blog/">Blog</a>
                      </li>
                    </ul>
                    <ul className="list-unstyled footer-widget__link-list">
                      <li>
                        <a href="/nosotros/">Nosotros </a>
                      </li>
                      <li>
                        <a href="/contacta/">Contacta</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-12">
                <div className="footer-widget footer-widget__gallery">
                  <h2 className="footer-widget__title">Cosas legales</h2>
                  <ul className="list-unstyled footer-widget__link-list">
                    <li>
                      <a href="https://eddelus.es/politica-de-privacidad/">Politica de privacidad </a>
                    </li>
                    <li>
                      <a href="https://eddelus.es/politica-de-cookies/">Politica de cookies</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-12">
                <div className="footer-widget footer-widget__about">
                  <h2 className="footer-widget__title">Sobre nosotros</h2>
                  <p className="footer-widget__text">
                    Queremos ayudar-te a disfrutar más con tu hobbie, por ello hacemos desarrollos que puedan facilitarte en la medida de lo posible que tu asociación, juego, módulo o cualquier otra cosa tenga los recursos que necesita
                  </p>
                  <div className="footer-widget__btn-block">
                    <a href="#none" className="thm-btn">
                      Contactar
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="site-footer__bottom">
          <div className="container">
            <p className="site-footer__copy">
              &copy; Copyright 2020 <a href="https://rolteca.com">Rolteca</a>
            </p>

          </div>
        </div>
      </footer>

      <div className="search-popup">
        <div className="search-popup__overlay custom-cursor__overlay search-overlay"></div>
        <div className="search-popup__inner">
          <form action="/busqueda" className="search-popup__form">
            <input
              type="text"
              name="search"
              placeholder="¿Qué estás buscando?"
            />
            <button type="submit">
              <i className="kipso-icon-magnifying-glass"></i>
            </button>
            <div className="cancel"></div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Footer;
